document.addEventListener('DOMContentLoaded', () => {
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    
    if (getCookie("cookieConsent") === "true") {
        initializeGTM();
        document.getElementById('cookie-popup').classList.remove('show');
    }

    document.getElementById('accept-all').addEventListener('click', function() {
        setCookie("cookieConsent", "true", 365);
        initializeGTM();
        document.getElementById('cookie-popup').classList.remove('show');
    });

    document.getElementById('decline').addEventListener('click', function() {
        document.getElementById('cookie-popup').classList.remove('show');
    });

    function initializeGTM() {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-G-X8Q6MTMF5L');
    }

    const swiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        slideToClickedSlide: true,

        watchSlidesProgress: true,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
          },
      
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
        },
      
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            1200: {
               spaceBetween: 100,
            },
            1920: {
                spaceBetween: 160,
             },
         }
      
    });

    function retriggerVideos() {
        const videoContainers = document.querySelectorAll('.swiper-slide');
        videoContainers.forEach(container => {
            const video = container.querySelector('.examplevideo');
            video.pause();
            video.currentTime = 0;
        });

        const activeVideoContainer = document.querySelectorAll('.swiper-slide-active');
        activeVideoContainer.forEach(container => {
            const video = container.querySelector('.examplevideo');
            video.play();
        });
    }

    retriggerVideos();

    const progressBar = document.querySelector(".progress");
    progressBar.style.animationPlayState = "running";

    // Retrigger Animation on Slide Change

    function myEndFunction() {
        swiper.slideNext();
        progressBar.style.animation = "none";
        void progressBar.offsetWidth; // Triggers Reflow
        progressBar.style.animation = null;
    }

    // Reset Progress Bar On Slide Change

    swiper.on("slideChangeTransitionEnd", function () {
        progressBar.style.animation = "none";
        void progressBar.offsetWidth; // Triggers Reflow
        progressBar.style.animation = null;
        
        retriggerVideos();
    });

    // Pause Carousel/Progress Bar On Hover

    document.querySelectorAll(".swiper").forEach((item) => {
        item.addEventListener("mouseenter", function () {
            progressBar.style.animationPlayState = "paused";
        });
    });

    document.querySelectorAll(".swiper").forEach((item) => {
        item.addEventListener("mouseleave", function () {
            progressBar.style.animationPlayState = "running";
        });
    });
});

